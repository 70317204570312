<template>
  <div>
    <FormModal
      title="Cadastro de Rubricas Padrão"
      :opts="opts"
      :cols="colsForm"
      :opened.sync="isAdd"
      :value.sync="tmp"
      :errorMessage="errorMsgModal"
      maxWidth="800px"
      @update:value="changeValues($event)"
      @save="doSave"
    >
    </FormModal>

    <v-dialog v-model="empresasModal" scrollable persistent max-width="600">
      <v-card>
        <v-card-title class="pb-1 pr-8">
          <v-icon x-large class="mr-3">mdi-card-text-outline</v-icon>
          <h3 style="width: 90%; word-break: normal">
            Empresas que usam a rubrica<br />{{ rubricaNome }}
          </h3>
          <v-btn
            @click="empresasModal = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 form_container">
          <kore-table
            v-if="empresas.length > 0"
            :cols="empresaCols"
            :opts="opts"
            :rows="empresas"
            :has-filter="false"
            height="auto"
          ></kore-table>
          <div v-else>Nenhuma empresa utiliza esta rubrica.</div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            dark
            depressed
            @click="empresasModal = false"
          >
            Fechar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <kore-table :cols="cols" :opts="opts" :rows="fRows" :actionBarButtons="actionBarButtons" :hasFilter="true"
      @contextmenu="actContext" @rowstoprint="rowstoprint">
    </kore-table>
  </div>
</template>


<script>
import _ from "lodash";
import { BeneficiosEnum, BeneficiosLabels } from "@/core/enums/beneficios";

export default {
  components: {
    "FormModal": () => import('@/components/form-modal.vue'),
    "kore-table": () => import("../../components/table-v.vue"),
  },
  created: function () {
    this.doLoad();
  },
  methods: {
    actContext: function (v, e) {
      this.showContext({
        left: e.clientX,
        top: e.clientY,
        val: v,
        menu: this.options,
      });
    },
    doCancel: function () {
      this.isAdd = false;
      this.isDelete = false;
      this.tmp = { soma: 1 };
    },
    doSave: function () {
      if (this.isSend === false && this.validation.size == 0) {
        this.isSend = true;
        this.isErrorMessage = "";
        var url = "";
        if (this.tmp.id) {
          url = "/v1/sistema/destaques/" + this.tmp.id;
        } else {
          url = "/v1/sistema/destaques";
        }

        let newValue = {
          ...this.getTmpFs(),
          ...this.getTmpTs(),
          trabalho: this.tmp.trabalho,
          Subtract: this.tmp.somar,
          nome: this.tmp.nome,
          id: this.tmp.id,
          formula: null,
        }

        const fbase = this.tmp.FBase;
        const tbase = this.tmp.TBase;

        if(fbase) newValue[fbase] = 1
        newValue[tbase] = 1
        newValue['Subtract'] = this.tmp.soma;

        this.korePost(url, newValue, (a) => {
          this.isSend = false;
          if (a.error) {
            this.isErrorMessage = a.error;
            this.$notify({
              group: "geral",
              duration: 10000,
              type: "error",
              title: "Erro",
              text: this.isErrorMessage,
            });
          } else {
            this.isAdd = false;
            this.doLoad();
            this.doCancel();
          }
        });
      } else {
        this.$notify({
          group: 'geral',
          type: 'error',
          duration: 5000,
          title: 'Campos inválidos',
          text: `Existem campos obrigatórios que não foram preenchidos: ${this.validationFields()}`,
        });
        return false;
      }
    },
    validationFields() {
      const substituicoes = {
        TBase: ' Aplicações valor',
        trabalho: ' Benefício',
        nome: ' Descrição'
      }
      const validationItems = Array.from(this.validation.keys());
      const resultado = validationItems.map((value) => substituicoes[value]);
      return resultado;
    },
    getTmpFs() {
      return {
        FHoras: -1,
        FDias: -1,
        FTotal: 0,
        FBase: 0,
      }
    },
    getTmpTs() {
      return {
        TBase: 0,
        TTotal: 0,
        TDias: 0,
        THoras: 0,
      }
    },
    doAdd: function () {
      this.isAdd = true;
      this.tmp = { soma: 1 };
    },
    doEdit: function (rows) {
      this.tmp = _.find(this.rows, { id: rows.id });
      const values = Object.entries(this.tmp).filter((e) => e[1] == 1);
      let tValue = null;
      let fValue = null;
      values.forEach((e) => {
        if(e[0].indexOf('F', 0) == 0) {
          fValue = e[0];
        }
        if(e[0].indexOf('T', 0) == 0) {
          tValue = e[0];
        }
      })
      const newTmp = {
        'id': rows.id,
        'TBase': tValue,
        'FBase': fValue,
        'soma': rows.Subtract,
        'trabalho': rows.trabalho,
        'nome': rows.nome,
      }
      this.tmp = newTmp;
      this.isAdd = true;
    },
    doDelete: function (rows, c = false) {
      if (c === false) {
        this.deletes = rows;
        this.isDelete = true;
      } else {
        this.isSend = true;

        var url = "/v1/sistema/destaques";
        this.koreDelete(url, { delete: rows }, (a) => {
          this.isSend = false;
          if (a.error) {
            this.isErrorMessage = a.error;
          } else {
            this.doLoad();
            this.doCancel();
          }
        });
      }
    },
    doCustomize: function () {
      this.isCustomize = true;
    },
    closeAll: function () {
      this.isAdd = false;
      this.isCustomize = false;
      this.tmp = { soma: 1}
    },
    doLoad: function () {
      this.koreGet(this.loadURL, (a) => {
        this.setValidationItems();
        this.loaded = true;
        if (!a.error) {
          this.rows = a;
        }
      });
    },
    getEmpresas: function (rubricaId) {
      return this.apiResource(`v1/sistema/clientesTemplate/${rubricaId}`)
        .get()
        .then((response) => {
          this.empresas = response;
          return response;
        });
    },
    rowstoprint: function (resolve) {
      const oldStatusArray = this.opts.status;
      this.opts.status = [
        {
          id: -1,
          name: "Não",
        },
        {
          id: 1,
          name: "Sim",
        },
      ];
      resolve(this.rows);
      setTimeout(() => {
        this.opts.status = oldStatusArray;
      }, 1000);
    },
    changeValues(key) {
      Object.entries(key).forEach((i) => {
        if(i[0] == 'FBase' && i[1].length > 1) {
          this.tmp['FBase'] = ['FHoras'];
          this.errorMsgModal = 'No campo Aplicações referência, é possível selecionar apenas uma opção. Desmarque uma das caixas selecionadas e marque apenas a que deseja salvar';
          return false;
        } else {
          this.errorMsgModal = '';
        }

        if(this.validation.has(i[0])) {
          this.validation.delete(i[0]);
        }
      });
    },
    setValidationItems() {
      this.colsForm.forEach((col) => {
        if(col.rules && col.rules.length > 0) {
          this.validation.set(col.key);
        }
      })
    }
  },
  computed: {
    loadURL: function () {
      let { nome } = this.$store.getters.pageTableFilters;
      return `/v1/sistema/destaques${nome ? `?filters[nome]=${nome}` : ''}`;
    },
    cLabels: function () {
      return _.filter(this.labels, function (l) {
        return l.type != 0 && l.type != -1;
      });
    },
    fRows: function () {
      var filters = {};

      if (this.filterCliente != 0 || this.filterServico != 0) {
        if (this.filterCliente != 0) {
          filters["empresaId"] = this.filterCliente;
        }

        return _.filter(this.rows, filters);
      } else {
        return this.rows;
      }
    },
    colsForm() {
      return [
        {
          key: 'nome',
          name: 'Descrição',
          type: this.$fieldTypes.TEXT,
          colSize: 12,
          ajuda: 'Descrição da rubrica no cálculo.',
          rules: [{ rule: 'required'}],
        },
        {
          key: "FBase",
          name: "Aplicações referência",
          ajuda: 'Campo da FOPAG expresso em dias, horas ou percentual. Selecione apenas uma referência para cálculo',
          type: this.$fieldTypes.CHECKBOXES,
          colSize: 12,
          rel: { to: "tmpF", key: "id", name: "name" },
        },
        {
          key: "TBase",
          name: "Aplicações valor",
          ajuda: 'Campo da FOPAG expresso R$.  + Base = Acima “Total de Vencimentos” no cálculo, encargos incidem sobre a rubrica. + Total = Abaixo “Total de Vencimentos” no cálculo, encargos não incidem sobre a rubrica.',
          type: this.$fieldTypes.RADIO,
          colSize: 12,
          rel: { to: "tmpT", key: "id", name: "name" },
          rules: [{ rule: 'required'}],
        },
        {
          key: "soma",
          name: "A referência e/ou valor da rubrica deverá:",
          ajuda: 'Comportamento da rubrica no cálculo.',
          type: this.$fieldTypes.RADIO,
          rel: { to: "soma", key: "id", name: "name" },
        },
        {
          key: "trabalho",
          name: "Benefício",
          ajuda: 'Se entra no cálculo da Lei do Bem, Lei de Informática, Captação ou todos.',
          type: this.$fieldTypes.RADIO,
          rel: { to: "trabalho", key: "id", name: "nome" },
          rules: [{ rule: 'required'}]
        },
      ]
    },
    fields() {
      var fields = {};
      this.colsForm.forEach((col) => {
        fields[col.key] = col;
      });
      return fields;
    },
    empresaCols: function () {
      return [
        {
          key: "empresa",
          name: "Empresa",
        },
        {
          key: "rubId",
          name: "Código FOPAG",
        },
        {
          key: "codigoFopag",
          name: "Descrição FOPAG",
        },
      ];
    },
  },
  data: function () {
    return {
      actionBarButtons: [
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          action: () => {
            this.doAdd();
          },
        },
      ],
      deletes: [],
      isSend: false,
      isErrorMessage: "",
      empresasModal: false,
      empresas: [],
      validation: new Map(),
      rubricaNome: '',
      filterCliente: 0,
      filterServico: 0,
      isDelete: false,
      isAdd: false,
      isCustomize: false,
      tmp: {
        soma: 1
      },
      rows: [],
      options: [
        {
          name: "Ver empresas",
          limit: 1,
          icon: "",
          cb: (e) => {
            this.rubricaNome = e.nome;
            this.getEmpresas(e.id).then(() => {
              this.empresasModal = true;
            });
          },
        },
        {
          name: "Editar",
          limit: 1,
          icon: "",
          cb: (e) => {
            this.doEdit(e);
          },
        },
      ],
      opts: {
        tmpF: [
          {
            id: 'FHoras',
            name: 'Horas',
          },
          {
            id: 'FDias',
            name: 'Dias',
          },
        ],
        tmpT: [
          {
            id: 'TBase',
            name: '+ Base',
          },
          {
            id: 'TTotal',
            name: '+ Total',
          },
          {
            id: 'THoras',
            name: 'Horas',
          },
          {
            id: 'TDias',
            name: 'Dias',
          },
        ],
        soma: [
          {
            name: 'Somar',
            id: 1,
          },
          {
            name: 'Subtrair',
            id: -1,
          },
        ],
        status: [
          {
            id: -1,
            name: '<i class="v-icon notranslate mdi mdi-checkbox-blank-circle-outline theme--dark" style="color: #d0d0d0"></i>',
          },
          {
            id: 1,
            name: '<i class="v-icon notranslate mdi mdi-checkbox-blank-circle theme--dark" style="color: #367be2"></i>',
          },
        ],
        trabalho: [
          { id: BeneficiosEnum.LEI_DO_BEM, nome: BeneficiosLabels.LEI_DO_BEM },
          {
            id: BeneficiosEnum.LEI_DE_INFORMATICA,
            nome: BeneficiosLabels.LEI_DE_INFORMATICA,
          },
          { id: BeneficiosEnum.CAPTACAO, nome: BeneficiosLabels.CAPTACAO },
          {
            id: BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
            nome: "Todos os benefícios",
          },
        ],
      },
      cols: [
        {
          key: "id",
          name: "ID",
          size: 100,
          align: 0,
          show: true,
          editable: false,
          type: 0,
        },
        {
          key: "nome",
          name: "Nome",
          size: 0,
          align: -1,
          show: true,
          editable: true,
          filterable: true,
          type: 1,
        },
        {
          key: "quantidade_empresas",
          name: "Clientes",
          size: 0,
          show: true,
          editable: false,
          type: 1,
        },
        {
          key: "FBase",
          name: "Referência + Base",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "FTotal",
          name: "Referência + Total",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "FHoras",
          name: "Referência como Horas",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "FDias",
          name: "Referência como Dias",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "TBase",
          name: "Valor + Base",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "TTotal",
          name: "Valor + Total",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "THoras",
          name: "Valor como Horas",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "TDias",
          name: "Valor como Dias",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "Subtract",
          name: "Subtrair",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "trabalho",
          name: "Benefício",
          show: true,
          editable: true,
          rel: { to: "trabalho", key: "id", name: "nome" },
        },
        {
          key: "formula",
          name: "Fórmula",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 1,
        },
      ],
    };
  },
  watch: {
    loadURL: function () {
      this.doLoad();
    }
  }
};
</script>

<style scoped lang="scss">
.modal-dialog-scrollable .modal-body::-webkit-scrollbar {
  width: 3px;
}

.overflow {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 50;
}

.kmodal {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 60;
  transform: translate(-50%, -50%);
  min-width: 300px;
  min-height: 300px;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px;
}

.actions {
  div {
    float: left;
    margin-right: 40px;
    cursor: pointer;
    font-weight: bold;
    color: #000000;

    img {
      margin: 5px;
    }
  }

  &:after {
    clear: both;
    content: " ";
  }
}

.filter {
  select {
    height: 33px;
    width: 100%;
  }
}
</style>
